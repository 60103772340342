import {
  sendGet,
  getApiPath,
  composeQueryParamUrl,
} from './requestUtils';
import { UserInfo } from './types';
import get from 'lodash/get';
import { getState } from '../../src/redux/store/store';

export default class SessionApi {
  static getUserInfoProtected() {
    return sendGet<UserInfo>('auth/userInfoProtected', null, 'json');
  }

  static getUserInfo() {
    return sendGet<UserInfo>('auth/userInfo', null, 'json');
  }

  static getUserServices() {
    let store = getState();
    return sendGet<any>(
      get(
        store,
        'config.o2Config["funnelui.cpservices.services.url"]',
        null
      ),
      {
        serviceTypes: get(
          store,
          'config.o2Config["funnelui.cpservices.services.param.serviceTypes"]',
          null
        )
      },
      'json'
    );
  }

  static getConfig() {
    return sendGet<any>('funnel/public/v1/config', null, 'json');
  }

  static setContextAndRedirect(pageToRedirect: any) {
    let store = getState();
    let wdeEndpoint = get(store, 'config.o2Config["link.wde"]', null);
    while (wdeEndpoint === null) {
      store = getState();
      wdeEndpoint = get(store, 'config.o2Config["link.wde"]', null);
    }

    const loginUrl = getApiPath(
      `${wdeEndpoint}${encodeURIComponent(
        pageToRedirect
          ? window.location.origin + pageToRedirect
          : window.location.origin + '/funnel'
      )}`
    );
    window.location.href = loginUrl;
    return 'ok';
  }

  static loginAndRedirect(pageToRedirect: any, params: any = false) {
    let store = getState();
    let loginEndpoint = get(store, 'config.o2Config["link.login"]', null);
    while (loginEndpoint === null) {
      store = getState();
      loginEndpoint = get(store, 'config.o2Config["link.login"]', null);
    }

    const QUERY_PARAMS = composeQueryParamUrl({
      autologinToken: params.token ?? undefined,
      forceLogin: true,
      redirectTo: encodeURIComponent(pageToRedirect),
    });

    window.location.href = `${loginEndpoint}${QUERY_PARAMS}`;

    return 'ok';
  }

  static loginAndDoubleRedirect(
    pageToRedirectFirst: any,
    pageToRedirectSecond: any
  ) {
    let store = getState();
    let loginEndpoint = get(store, 'config.o2Config["link.login"]', null);
    while (loginEndpoint === null) {
      store = getState();
      loginEndpoint = get(store, 'config.o2Config["link.login"]', null);
    }
    const loginUrl = getApiPath(
      `${loginEndpoint}?redirectTo=${encodeURIComponent(
        pageToRedirectFirst +
        encodeURIComponent(
          pageToRedirectSecond
            ? window.location.origin + pageToRedirectSecond
            : window.location.origin + '/funnel'
        )
      )}`
    );
    window.location.href = loginUrl;
    return 'ok';
  }

  static loginForceAndDoubleRedirect(
    pageToRedirectFirst: any,
    pageToRedirectSecond: any,
    params: any = false,
  ) {
    let store = getState();
    const ORIGIN_URL = window.location.origin;
    let secondPage = '/funnel';
    if (pageToRedirectSecond) secondPage = pageToRedirectSecond;
    const composedPageToRedirectSecond = ORIGIN_URL + secondPage;
    let loginEndpoint = get(store, 'config.o2Config["link.login"]', null);
    while (loginEndpoint === null) {
      store = getState();
      loginEndpoint = get(store, 'config.o2Config["link.login"]', null);
    }

    const QUERY_PARAMS = composeQueryParamUrl({
      autologinToken: params.token ?? undefined,
      forceLogin: true,
      redirectTo: encodeURIComponent(
        pageToRedirectFirst + encodeURIComponent(composedPageToRedirectSecond)
      ),
    });

    window.location.href = `${loginEndpoint}${QUERY_PARAMS}`;
    return 'ok';
  }

  static logoutAndRedirect(pageToRedirect: any) {
    let store = getState();
    let logoutEndpoint = get(store, 'config.o2Config["link.logout"]', null);
    while (logoutEndpoint === null) {
      store = getState();
      logoutEndpoint = get(store, 'config.o2Config["link.logout"]', null);
    }
    const logoutUrl = getApiPath(
      `${logoutEndpoint}?redirectTo=${encodeURIComponent(
        pageToRedirect
          ? window.location.origin + pageToRedirect
          : window.location.origin + '/funnel'
      )}`
    );
    window.location.href = logoutUrl;
    return 'ok';
  }
}
